<template>
  <div class="tablerow d-flex align-items-center justify-content-between dcolumn">
    <div class="tablerowone d-flex align-items-center">
      <!-- <h5>טיסה חזור לתל אביב</h5> -->
      <h5>{{$t("sabre.booking.baggage-box.flight-to", {dest: cityName})}}</h5>
      <div class="d-flex align-items-center">
        <div class="tablerowonenumber">
          <h6>
            <strong>${{price}} </strong> {{seatNumber}}
          </h6>
        </div>
        <!-- <p>בטל</p> -->
      </div>
    </div>
    <div class="tablerowone">
      <a href="#" class="btn btn-outline-primary" @click="() => showChooseSeatModal()">{{$t("sabre.buttons.choose-seat")}}</a>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  name: 'seat-one-way-item',
  props: {
    dest: {
      type: String,
      default: '',
    },
    flightIndex: {
      type: Number,
      default: 0,
    },
    segmentIndex: {
      type: Number,
      default: 0,
    },
    passengerIndex: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapGetters({
      destinationList: 'GET_DESTINATION_IMAGES',
      selectedBrandIndex: 'GET_SABRE_FO_BOOKING_TICKET_BRAND_DATA',
      selectedSeatData: 'GET_SABRE_FO_BOOKING_SELECTED_SEAT_DATA',
    }),
    destinations() {
      const { destinationList } = this;
      // return destinationList?.filter((dest) => dest.forSabreFlight) || [];
      return destinationList?.filter((dest) => dest.name) || [];
    },
    cityName() {
      const { destinations, dest } = this;
      return destinations.find((d) => d.code === dest)?.name_Loc?.content || '';
    },
    curPassengerSeatData() {
      const { passengerIndex, flightIndex, segmentIndex, selectedSeatData } = this;
      return selectedSeatData?.find((seat) => seat.passengerIndex === passengerIndex && seat.flightIndex === flightIndex && seat.segmentIndex === segmentIndex) || null;
    },
    price() {
      const { curPassengerSeatData } = this;
      return curPassengerSeatData?.price || 0;
    },
    seatNumber() {
      const { curPassengerSeatData } = this;
      const seatNumber = curPassengerSeatData?.seatNumber || '';
      return seatNumber ? `${seatNumber[0]}-${seatNumber.slice(1)}` : '';
    },
  },
  methods: {
    showChooseSeatModal() {
      if (this.selectedBrandIndex < 0) {
        this.$store.commit('SET_SABRE_ERROR_ALERT_MESSAGE', 'You must select ticket type first.');
        return;
      }
      this.$emit('setSegmentData');
      this.$bvModal.show('seat-selection-table');
    },
  },
};
</script>
